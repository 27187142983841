<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <b-card style="padding: -10px">
          <tahun-angggaran style="margin-top: -10px; margin-bottom: -20px;"></tahun-angggaran>
        </b-card>
      </b-col>
      <b-col md="8">
        <statistik></statistik>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <table_data></table_data>
          <detail-sub-kegatan></detail-sub-kegatan>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCard, BRow, BCol, BAvatar
} from 'bootstrap-vue'
import TahunAngggaran from './component/tahunAngggaran.vue'
import Statistik from './component/statistik.vue'
import table_data from './component/tabel.vue'
import DetailSubKegatan from './component/detailSubKegiatan.vue'
export default {
  components: {
    BTabs, BRow, BCol,
    BTab, BCard,
    TahunAngggaran, BAvatar,
    Statistik, table_data,
    DetailSubKegatan
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>