<template>
  <b-card no-body class="card-statistics">
    <b-card-body class="statistics-body" style=" margin-top: -10ps; margin-bottom: -10px;">
      <b-row>
        <b-col xl="3" sm="3">
          <b-media no-body style=" margin-top: -10px; margin-bottom: -10px;">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info" rounded="">
                <feather-icon icon="ArchiveIcon" size='30' />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h2 class="font-weight-bolder mb-0 text-info">
                10
              </h2>
              <b-card-text class="font-small-3 mb-0 text-info">
                Kegiatan
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="3" sm="3">
          <b-media no-body style=" margin-top: -10px; margin-bottom: -10px;">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success" rounded="">
                <feather-icon icon="ListIcon" size='30' />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h2 class="font-weight-bolder mb-0 text-success">
                30
              </h2>
              <b-card-text class="font-small-3 mb-0 text-success">
                Sub Kegiatan
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col xl="6" sm="4">
          <b-media no-body style=" margin-top: -10px; margin-bottom: -10px;">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary" rounded="">
                <feather-icon icon="AwardIcon" size='30' />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h3 class="font-weight-bolder mb-0 text-primary">
                <sup>Rp. </sup> 1.000.000.000.00,-
              </h3>
              <b-card-text class="font-small-3 mb-0 text-primary">
                Total Anggaran 30 Sub Kegiatan
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '4',
          subtitle: 'Kegiatan',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '50',
          subtitle: 'Sub Kegiatan',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '50.0000.000.000',
          subtitle: 'Total Anggaran',
          customClass: '',
        },
      ],
    }
  }

}
</script>
