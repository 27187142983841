<template>
  <div>
    <b-modal id="modal-sub-kegiatan" title="Detail Sub Kegiatan" ok-disabled no-close-on-backdrop scrollable hide-footer
      size="xl">
      <b-col>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-alert variant="primary" show>
                  <div class="alert-body">
                    <b-row>
                      <b-col md="6">
                        <h6><small class="text-primary">Tujuan </small><br><small>
                            Meningkatkan Kualitas Jalan Provinsi <br>
                            <small class="text-primary">Indikator Tujuan </small><br>Persentase jalan provinsi sesuai
                            standar jalan kolektor dan kondisi permukaan mantap
                          </small></h6>
                      </b-col>
                      <b-col md="6">
                        <h6><small class="text-primary">Sasaran </small><br><small>
                            Meningkatnya Panjang Jalan Provinsi dengan Kualitas Struktur dan Kapasitas Sesuai Standar
                            Jalan
                            Kolektor<br>
                            <small class="text-primary">Indikator Sasaran </small><br>
                            Persentase panjang jalan provinsi sesuai standar jalan kolektor
                          </small></h6>
                      </b-col>
                    </b-row>
                  </div>
                </b-alert>
              </b-col>
              <b-col md="12">
                <b-alert variant="info" show>
                  <div class="alert-body">
                    <b-row>
                      <b-col md="12">
                        <b-row>
                          <b-col md="2">
                            <h6 class="text-primary"><small><strong>1 03 02</strong></small></h6>
                          </b-col>
                          <b-col md="10">
                            <h6 class="text-primary"><small><strong>PROGRAM PENGELOLAAN SUMBER DAYA AIR
                                  (SDA)</strong></small></h6>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="2">
                            <h6 class="text-primary"><small><strong>1 03 02 1,01</strong></small></h6>
                          </b-col>
                          <b-col md="10">
                            <h6 class="text-primary"><small><strong>Pengelolaan SDA dan Bangunan Pengaman Pantai pada
                                  Wilayah Sungai Lintas
                                  Daerah
                                </strong></small>
                            </h6>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="2">
                            <h6 class="text-primary"><small><strong>1 03 02 1,01 01</strong></small></h6>
                          </b-col>
                          <b-col md="10">
                            <h6 class="text-primary"><small><strong>Penyusunan Rencana Teknis dan Dokumen Lingkungan Hidup
                                  untuk Konstruksi
                                  Bendungan, Embung, dan Bangunan Penampung Air Lainnya</strong> </small></h6>
                          </b-col>
                        </b-row>
                      </b-col>
                      <hr>
                      <b-col md="12">
                        <b-alert variant="primary" show>
                          <div class="alert-body text-center">
                            <span>Total Anggaran Sub Kegiatan<br><strong style="font-size: 20px;"><sup>Rp.
                                </sup>10.000.000.000,-</strong> </span>
                          </div>
                        </b-alert>
                      </b-col>
                      <hr>
                      <b-col md="12">
                        <h6><small class="text-primary">Kinerja</small><br><small>
                            Tersusunnya Rencana Teknis dan Dokumen Lingkungan Hidup untuk Konstruksi Bendungan, Embung,
                            dan
                            Bangunan Penampung Air Lainnya
                          </small></h6>
                        <h6><small class="text-primary">Indikator</small><br><small>
                            Tersusunnya Rencana Teknis dan Dokumen Lingkungan Hidup untuk Konstruksi Bendungan, Embung,
                            dan
                            Bangunan Penampung Air Lainnya
                          </small></h6>
                        <h6> <small> <strong>Satuan Dokumen</strong></small></h6>
                      </b-col>
                    </b-row>


                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <h4><feather-icon icon="GridIcon" class="mr-50" size='15' /> Rincian Anggaran Balai / Bidang</h4>
            <tabel-penganggaran></tabel-penganggaran>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">

          </b-col>
        </b-row>
      </b-col>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BCol, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TabelPenganggaran from './tabelPenganggaran.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BCol, BRow,
    TabelPenganggaran,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-sub-kegiatan')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>